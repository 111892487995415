import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ImageTriplet = makeShortcode("ImageTriplet");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Ashu's Biodata`}</h2>
    <p>{`Hi, I'm Ashu :)`}</p>
    <p>{`If you're seeing this (`}<em parentName="p">{`unfinished, I'll get there`}</em>{`) page, it's likely we have friends in common. You might be interested in dating me, or simply curious about my avant-garde approach to finding a life partner.`}</p>
    <p>{`It's lovely to meet you and I hope you enjoy getting to know me a bit!`}</p>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640460/Coaching%20Site/biodata/bdf96pn8zlmv0xbkvuhv.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640460/Coaching%20Site/biodata/lnhodi8tuobryk73atgq.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689640460/Coaching%20Site/biodata/apxdqnkodbsm7x5lkqpw.jpg" mdxType="ImageTriplet" />
    <h2>{`tl;dr`}</h2>
    <p>{`I'm an artist and entrepreneur. I'm deeply principled. I geek out on creativity, learning, and designing systems.`}</p>
    <p>{`I spent a decade founding `}<a parentName="p" {...{
        "href": "https://ashu.xyz/makeschool"
      }}>{`Make School`}</a>{` - a new university in San Francisco, then a year as a visiting parter at Y Combinator. I'm now coaching startup founders, consulting on special projects, and producing music under the moniker `}<a parentName="p" {...{
        "href": "https://studiocollective.xyz/tosh"
      }}>{`Tōsh`}</a>{` while supporting SF's music scene with the `}<a parentName="p" {...{
        "href": "https://studiocollective.xyz"
      }}>{`Studio Collective`}</a>{`.`}</p>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640465/Coaching%20Site/biodata/cr2hliusveipyibylhoz.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640459/Coaching%20Site/biodata/cxsrfdowau8w8bxavzmf.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689663428/Coaching%20Site/biodata/IMG_6208_em0mnd.jpg" mdxType="ImageTriplet" />
    <p>{`I'm looking for a life partner to grow with and support in finding Ikigai. I don't think I want kids, but I do want to build a long term community of friends and family with my partner in San Francisco :)`}</p>
    <p>{`I wrote this biodata to share my values, what I'm looking for, and my best and worst traits. I've asked friends and family to share it with our extended community.`}</p>
    <p>{`Now for the long version - apologies for the lack of brevity...`}</p>
    <h2>{`What is biodata?`}</h2>
    <p>{`Biodata in South Asian culture loosely translates to "dating resume". It's a document passed around by close community / family to help identify potential life partners.`}</p>
    <p>{`For my parents generation, this was part of an arranged marriage process. My parents met for 45 minutes before deciding to get married - and are still married 35 years later! In modern Indian culture, matches aren't arranged - only introduced.`}</p>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/bers5e0v4vszl6unsivw.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/oly7gqeuyk8y1qb1izbh.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/omjt6fbl8dm6wagfscgm.jpg" mdxType="ImageTriplet" />
    <p>{`In a way, a biodata is like a profile on a dating app, but different in two important ways:`}</p>
    <ol>
      <li parentName="ol">{`The content on biodata is rooted in long term compatibility - values, culture, commitment - rather than pictures and short term interests.`}</li>
      <li parentName="ol">{`The biodata are not circulated at random by an algorithm, they are shared to extended community members.`}</li>
    </ol>
    <h2>{`Why make a biodata?`}</h2>
    <p>{`A few years ago I was having tea with friends in New York City discussing our dating habits. We each shared the three most important characteristics we were seeking in a life partner. And we also shared the ways we do initial sourcing and screening of potential partners.`}</p>
    <p>{`Unsurprisingly, we all biased our initial screening/sourcing on characteristics that make us attracted to people, rather than characteritics that demonstrated values alignment or willingness to make the type of commitments we seek.`}</p>
    <p>{`I do feel attraction is important for love, but I've come to believe it's far more commonly found than values alignment and commitment (especially in San Francisco, a city that has always invited unique approaches to building a family structure). So long as some level of attraction - ie. enjoying spending time together - exists, love will grow as a function of alignment and commitment. Just as it does with long term friendships.`}</p>
    <p>{`I also turned 30 a couple years ago, which marks my 20th year in the Bay Area. Being so rooted in a place makes casual dating a bit more complicated. I come with 20 years of friendships, commitments, and baggage that will be unavoidable in a city that oft feels uncomfortably small.`}</p>
    <p>{`Thus building a partnership is not just a marriage between me and my partner, but also a marriage between our communities. Communities that will hopefully come together to interact and uplift each other for decades to come.`}</p>
    <p>{`With this lens, the traditional Indian model of courtship feels perhaps more intentional and pragmatic than the flavor of the month dating rituals of San Francisco. It emphasizing values alignment and commitment, and acknowledges that marriage is about joining communities in addition to joining people.`}</p>
    <p>{`Consider this my attempt to preserve the intention of Indian marriage culture while interpreting it the most modern San Francisco way - by making a personal website :). And consider it my attempt to demonstrate the thoughtfulness and intentionality I would bring to our partnership - should we choose to explore one.`}</p>
    <img {...{
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1689640462/Coaching%20Site/biodata/vohgwhaxf6lw5vipupmp.jpg",
      "width": "100%"
    }}></img>
    <h2>{`My values`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Ahimsa`}</strong>{`
Ahimsa the Indian principle of nonviolence towards living beings developed in the vedic period (1500BCE-500BCE). Ahimsa is the driving principle in how I consider interacting with others - my words, actions, and notably the choice to be vegan.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Ikigai`}</strong>{`
Ikigai is a Japanese word, loosely translated to "what makes life worth living". To me, Ikigai is the discovery of a sense of purpose which guides how we spend our time. To continually seek this purpose (as we grow and change) and focus our time and energy towards it. My life has centered around my own Ikigai and supporting others in discovering and pursuing theirs.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Collectivism`}</strong>{`
I believe humans are inherently interdependent. Our society only exists through heavy collaboration, and even our individual personalities are most often an average of the people we've surrounded ourselves with. Given that we would not be able to live, eat, learn, create, or play without support of society, I feel we all have a duty to contribute back to the collective both through active efforts and through our daily passive choices. On balance, I value the needs of the community over the needs of the individual. This belief has been a strong drive for me to engage in impact-oriented fields such as education. More recently it has driven me to reflect on how we can adapt our current economic system to a more inclusive form of post-capitalism and move beyond rigid country borders to see all humans as one collective. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Inclusivity`}</strong>{`
I've observed that collectivism often creates implicit exclusion. Strong communities are built on tightly shared values, which deepen bonds but also create feelings of otherness for people whose values differ (who are often afraid to speak up). Given this, inclusivity is an ideal to strive for which encompasses anti-racism, feminism, anti-ableism, and other forms of equality - both on a systemic level and a human level. It is not something I will ever be perfect at, but it is something I reflect on and seek to improve on each day.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Actions speak louder`}</strong></p>
        <p parentName="li">{`Words are imperfect. They're influenced by our momentary emotions or simply imprecise. And even if we speak the same language, nonverbal expressions and mannerisms differ greatly between subcultures. We exaggerate and undercommunicate, even with the best intentions. Given this, I put more weight on actions`}</p>
      </li>
    </ol>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640463/Coaching%20Site/biodata/io8jrl5jkbnqhzzc8ckb.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640463/Coaching%20Site/biodata/me8m3jdcbvd0wedorbl4.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689640463/Coaching%20Site/biodata/zt8jb3t2fbzjxqtmg8vp.jpg" mdxType="ImageTriplet" />
    <h2>{`What I'm looking for in a partner`}</h2>
    <p><em parentName="p">{`Everyone has different lived experiences and is on different life paths, and you may not yet feel you meet one of these criteria. That's totally OK. It's impossible to find a partner based on a checklist, so I probably want to meet you if you relate to most of these statements :)`}</em></p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Lifelong commitment`}</strong></p>
        <p parentName="li">{`I am looking for a forever friend and companion. Someone to explore depth of human connection with and depth of community connection. I want to build a life together. And do so with emotional security where we can feel confident in our connection and attachment regardless of how our personalities shapeshift. I want to be committed to being a great partner to each other and accepting of when we fail. I want to be comfortable with conflict - even those without any resolution - and comfortable taking space when necessary. I want to prioritize security and stability in partnership over exploration and discover in dating. Of course there may be extreme circumstances that cut the partnership short. But I want to go in with the intention of a lifelong commitment that will enable us to focus our time and energy on learning, creating, and supporting our communities.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Creativity, agency, and craftsmanship`}</strong>{`
I get along best with people who are creative - often artists and entrepreneurs. Who feel agency to design the life that they want and play outside of default societal structures. Who have high self-confidence, have a growth mindset, and don't give in to their limiting beliefs. Who are seeking to refine their craft - whether that craft is an art, building an organization, contributing to their community, or whatever inspires them. I want our relationship to be supportive of our external goals and be accepting of heavy and unpredictable external commitments. A relationship that supports each other in striving for excellence in what we do.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`High executive function and financial grounding`}</strong>{`
I want us to be able to share in the operational work of running a household and supporting our friends/family. We both should be organized, responsive, and live within our financial means. I'd want to avoid lifestyle creep that demands that we pursue high compensation work - so we can be free to pursue the arts or civic service. Of course, there may be unexpected health or other issues that reduce either of our executive function - that's OK too. But when we're both healthy, I'd love to share the load!`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Community over individualism`}</strong>{`
I want to be in a partnership where we prioritize the partnership over our individual needs (on balance, not in the absolute). I feel this is key to a lasting relationship. Similarly, I feel the key to healthy community relationships (and even personal fulfillment) is in prioritizing our broader community over our individual needs. Of course, this is a spectrum - and both extremes can be unhealthy. I'd love for our family to lean further towards community than the default in San Francisco.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Cultural awareness and refined taste`}</strong>{`
This is a tough one to define with precision, but it's one that has come up regularly in my explorations in both entrepreneurship and arts along with my reflections of my own identity. I'm Indian and grew up across cultures, spending my childhood between Hong Kong, Mumbai, and the Bay Area. Much of my perspectives and personality are rooted in Asian values and the experience of seeking belonging in a world different than me. I also spent a decade running a college which gave me a strong awareness of life stories and experiences of people from all walks of life. This leads me to have certain perspectives that can feel radical and I tend to push my communities hard on being inclusive - which can rub people the wrong way. I think fundamentally cultural awareness and taste both come down to research and understanding. The more we invest in exploring more about the world - through travel, having diverse friends, having frank and candid conversations - the more we become aware of the struggles of the hundreds of diasporas attempting to share space in California. I believe taste is most commonly developed by an active or passive study of cultures, languages, and artistic medium. I'd love for us to study our areas of interest to a sufficient depth to hold an understanding of the culture/lineage and hold opinions on what excellence looks like. And I'd love for us to hold understanding of each others' unique life experiences that drive our behavior, mannerisms, and perspectives.`}</p>
      </li>
    </ol>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640462/Coaching%20Site/biodata/mbmyb5wp14pzpmkcc0zt.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/qpglw0hpp5ene0zone6y.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/qpglw0hpp5ene0zone6y.jpg" mdxType="ImageTriplet" />
    <h2>{`A few dealbreakers`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`I am straight`}</strong>{`
Sorry guys :)`}</li>
      <li parentName="ul"><strong parentName="li">{`I want a vegan or vegetarian partner`}</strong>{`
I am deeply uncomfortable with the idea of killing animals for pleasure and the sight / smell of meat makes me feel ill. It's important to me that my home and family feels safe without having to think about this.`}</li>
      <li parentName="ul"><strong parentName="li">{`I don't think I want kids`}</strong>{`
This might change one day, but it seems unlikely. I don't want to have kids unless I'm a "fuck yes" on it because I feel it would lead to a less joyful childhood and put an undue burden on my partner. The world has enough heterosexual couples where the woman bears the brunt of raising kids, it doesn't need another ;). Instead of having kids, I'd love to spend our time focused on mastering our respective crafts, investing in our friendships/communities, and being civically engaged.`}</li>
      <li parentName="ul"><strong parentName="li">{`I am rooted in the SF Bay Area for the long term`}</strong>{`
I've been here 20 years, my friends/family are here, and it's one of the only cities in the world that shares my values and has representation of my culture. I've also realized that it's much more practical to do creative and ambitious things in a place where you have a strong support network. That said, I'm definitely open to living in different places shorter term and I travel frequently. I also want us to travel separately and am comfortable with spending 3-6 months a year in different places. If we'll be rooted to each other and the Bay Area for our whole lives - it would be great to have time exploring the rest of the world :)`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm seeking monogomy`}</strong>{`
I conceptually like idea of polyamory and find myself frequently attracted to people I spend time with. But in practice polyamory seems to often create emotional strain on primary partnerships `}<em parentName="li">{`and`}</em>{` the surrounding community (usually the women who serve as the unpaid community therapists). I've also seen many of my male friends behave hurtfully towards their partners in these contexts, which gives me low confidence that I would do better. I `}<em parentName="li">{`might`}</em>{` be open to casual connections outside the partnership while solo traveling - but with a firm boundary to keep it outside the bounds of our close communities.`}</li>
      <li parentName="ul"><strong parentName="li">{`Shoes off in the house`}</strong>{`
If you know, you know ;)`}</li>
    </ul>
    <img {...{
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1689663427/Coaching%20Site/biodata/IMG_2207_qri4ep.jpg",
      "width": "100%"
    }}></img>
    <h3>{`My best qualities`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`I like helping and teaching people :)`}</strong>{`
I get a lot of satisfaction from helping my friends and community with projects they're working on. I've spent much of my life teaching and coaching others - at Make School, YC, the Studio Collective. I'm good at helping people build plans to learn or do hard things. I'd be excited to help you realize your dreams!`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm curious and learn quickly`}</strong>{`
I love reading and researching about many topics. I learn quickly, if I'm not good at something to start I can usually get there fast. And I tend to be quite responsive to feedback, which is helpful in a partnership.`}</li>
      <li parentName="ul"><strong parentName="li">{`I love focused quality time`}</strong>{`
Long hikes, lazy days in bed, nights out dancing. 1:1 intimacy with someone I care about is my favorite, and I can be good at focused listening in these containers (though not always outside of these containers :p).`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm great at building things`}</strong>{`
I'm a proficient engineer in software and sound and great at designing pragmatic and human systems. This also tends to make me good at designing systems to run my own life. I'm quite organized and have high executive function - so it's easy for most people to work with me.`}</li>
      <li parentName="ul"><strong parentName="li">{`I have strong friendships and communities`}</strong>{`
I have an amazing network of creative and entrepreneurial friends, former students, and former colleagues around the world. This gives an amazing lens through which to travel and learn about the world, and makes me feel immensely supported in whatever I want to do in life.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm creative`}</strong>{`
My creativity gives me lots of ideas (perhaps too many :p) and enables me to be quite generative across medium - music, writing, photography, engineering. This tends to make me great at problem solving or debugging issues that show up in work or in life.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm thoughtful, passionate, and principled`}</strong>{`
I do my best to stay true to my values and principles (which I've shared above) and behave in an ethical way. I also challenge and support my communities in doing the same (which I think is a good thing :). I care deeply about issues around education, inclusion, feminism, immigration, and housing - which influences how I live my life.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm a chameleon`}</strong>{`
Given that I've grown up around the world and traveled a lot, I tend to be pretty good at getting along in most social situations and quickly adaptable to most unexpected circumstances. I'm usually confident and charismatic in social contexts. I tend to be resilient through tough times.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm optimistic`}</strong>{`
On the whole, I tend to resolve to an optimistic perspective about my life, my emotions, and my abilities. I am good at working through adversity, overcoming limiting beliefs, and seeing the good in others. It's not an absolute, I definitely have many moments of negative thinking, but the optimism almost always wins out.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm open minded`}</strong>{`
I tend to be comfortable with people living as they'd like to live, so long as it's not hurting those around them. I like traveling to new places and discovering new things. Outside of the values I've laid out in this document - which I've observed to stay with me throughout my life - I tend to be pretty open to change.`}</li>
      <li parentName="ul"><strong parentName="li">{`My anxiety allows me to predict risk`}</strong>{`
I tend to be pretty good at evaluating risk of situations - both in my own life but also for communities and organizations. While anxiety can also show up in less fun ways, I find it a very useful tool`}</li>
    </ul>
    <h3>{`The things that suck about me`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`I'm very principled`}</strong>{`
This can feel antagonistic, drive insecurity, and feel like I don't accept others.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm often serious`}</strong>{`
I care a lot about the world, and pursuing excellence. This can feel intimidating or draining for people.`}</li>
      <li parentName="ul"><strong parentName="li">{`I can get frustrated and single track minded`}</strong>{`
This impacts my mood, and I complain/rant about things I am frustrated with. Usually I just need a hug and an ear.`}</li>
      <li parentName="ul"><strong parentName="li">{`I like to write a lot, and text a lot`}</strong>{`
Probably helps if you like to read / are a fast reader :)`}</li>
      <li parentName="ul"><strong parentName="li">{`I talk fast, and can get excited in conversation`}</strong>{`
I can get stuck in "debate mode" so we'll need a method to avoid that`}</li>
      <li parentName="ul"><strong parentName="li">{`I give direct and unfiltered feedback`}</strong>{`
This can feel offputting and hurtful, especially in sensitive moments.`}</li>
      <li parentName="ul"><strong parentName="li">{`I struggle with anxiety`}</strong>{`
This can sometimes leads to depressive tendencies. I do my best to retreat to my own space in these moments, but if I'm in social settings it can lead me to be snappy or closed off from people.`}</li>
      <li parentName="ul"><strong parentName="li">{`I'm not always disciplined`}</strong>{`
Particularly about working out and eating healthy, though I expect social motivation in a partnership will help.`}</li>
      <li parentName="ul"><strong parentName="li">{`I haven't been in a long term romantic relationship`}</strong>{`
Which means I'll probably make some rookie mistakes :)`}</li>
    </ul>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/xxhlxsall086lhl6cj6z.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640469/Coaching%20Site/biodata/qvtsrt7qndircbz4uf44.png" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689663427/Coaching%20Site/biodata/IMG_0851_f0qarj.jpg" mdxType="ImageTriplet" />
    <h2>{`Fun facts`}</h2>
    <ul>
      <li parentName="ul">{`I've lived in 3 countries and traveled to 35`}</li>
      <li parentName="ul">{`My music studio upstairs of the Great Northern`}</li>
      <li parentName="ul">{`I've been to Burning Man since I was 20 years old`}</li>
      <li parentName="ul">{`I love concerts and dancing`}</li>
      <li parentName="ul">{`I was featured in the Nob Hill Gazette's Most Eligible San Franciscans list. I do not recommend.`}</li>
      <li parentName="ul">{`I almost always wear black`}</li>
    </ul>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1689640461/Coaching%20Site/biodata/ikygt3r4y6yj54cxqjhg.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1689640462/Coaching%20Site/biodata/xn5a82xjk8bh2cxckk66.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1689640460/Coaching%20Site/biodata/wdf1flvhuyyghh0ze5sd.jpg" mdxType="ImageTriplet" />
    <h2>{`Get in touch`}</h2>
    <p>{`Want to get to know me? Ask a friend to introduce us :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      